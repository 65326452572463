// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./RaTVq8A7m-0.js";

const cycleOrder = ["APvKMuncH", "zjZCtseh7"];

const serializationHash = "framer-VT9ZI"

const variantClassNames = {APvKMuncH: "framer-v-18mro22", zjZCtseh7: "framer-v-1x9er65"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}, zjZCtseh7: {damping: 11, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {sqKeqj321: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "APvKMuncH", "Variant 2": "zjZCtseh7"}

const getProps = ({height, id, tint, width, ...props}) => { return {...props, CyE7CB3gO: tint ?? props.CyE7CB3gO ?? "rgb(0, 255, 72)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "APvKMuncH"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tint?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CyE7CB3gO, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "APvKMuncH", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearnyb9uj = activeVariantCallback(async (...args) => {
await delay(() => setVariant("zjZCtseh7"), 1000)
})

const onAppeardmya20 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("APvKMuncH"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppearnyb9uj, zjZCtseh7: onAppeardmya20})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-18mro22", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"APvKMuncH"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({zjZCtseh7: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-8ivvro"} layoutDependency={layoutDependency} layoutId={"sg5vRNbU4"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 17, borderBottomRightRadius: 17, borderTopLeftRadius: 17, borderTopRightRadius: 17, opacity: 0.1}} transformTemplate={transformTemplate1} variants={{zjZCtseh7: {borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, opacity: 0.2}}}/><motion.div className={"framer-1llrj2e"} layoutDependency={layoutDependency} layoutId={"i5rHpsFzv"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, opacity: 0.5}} transformTemplate={transformTemplate1} variants={{zjZCtseh7: {opacity: 1}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-VT9ZI[data-border=\"true\"]::after, .framer-VT9ZI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VT9ZI.framer-1eypmd6, .framer-VT9ZI .framer-1eypmd6 { display: block; }", ".framer-VT9ZI.framer-18mro22 { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-VT9ZI .framer-8ivvro { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); left: 51%; overflow: visible; position: absolute; top: 51%; width: 16px; }", ".framer-VT9ZI .framer-1llrj2e { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 10px); left: 51%; overflow: visible; position: absolute; top: 51%; width: 10px; }", ".framer-VT9ZI.framer-v-1x9er65.framer-18mro22 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); }", ".framer-VT9ZI.framer-v-1x9er65 .framer-8ivvro { height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }", ".framer-VT9ZI.framer-v-1x9er65 .framer-1llrj2e { height: var(--framer-aspect-ratio-supported, 12px); width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zjZCtseh7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CyE7CB3gO":"tint"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerRaTVq8A7m: React.ComponentType<Props> = withCSS(Component, css, "framer-VT9ZI") as typeof Component;
export default FramerRaTVq8A7m;

FramerRaTVq8A7m.displayName = "Elements/Pulse Copy";

FramerRaTVq8A7m.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerRaTVq8A7m, {variant: {options: ["APvKMuncH", "zjZCtseh7"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, CyE7CB3gO: {defaultValue: "rgb(0, 255, 72)", title: "Tint", type: ControlType.Color}} as any)

addFonts(FramerRaTVq8A7m, [])